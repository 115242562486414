<template lang="pug">
  .success-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-email"
    )
    h3.nio-h3.text-primary-darker Success!
    p.nio-p.text-primary-dark(v-if="editing") You've succesfully updated your profile.
    p.nio-p.text-primary-dark(v-else) You've created a new profile. You can now start using it in your Data Streams.
    .actions
      NioButton(
        normal-secondary
        @click="close"
      ) Back
</template>

<script>

export default {
  props: {
    editing: Boolean, required: false, default: false
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.success-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 1rem
  h3
    margin-bottom: 0.5rem
  p
    margin-bottom: 2.5rem
    text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + *
      margin-left: 1rem
</style>
<template lang="pug">
  .profile-settings-page
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80"
        color="#1438F5"
        indeterminate
      )
    h1.nio-h1.text-primary-darker Profile Settings
    .sub-container
      .brand-id.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker The Trade Desk Brand ID
          .description.nio-p.text-primary-dark Enter The Trade Desk Brand ID for sharing partner data supplied by your The Trade Desk account representative.
        .filter-value(v-if="!loading || existingProfile")
          NioTextField(
            v-model="model.brandId"
            :disabled="existingProfile && !editing"
            :rules="[rules.required]"
            label="The Trade Desk Brand ID"
            validate-on-blur
          )
    .actions(v-if="!loading")
      NioButton(
        key="1"
        v-if="!editing && existingProfile"
        normal-secondary
        @click="editing = true"
      ) Edit Settings
      NioButton(
        vkey="2"
        v-if="editing || !existingProfile"
        :disabled="!valid || (existingProfile && !editing)"
        normal-primary
        @click="saveSettings"
      ) Save Settings
    NioDialog(
      v-model="successDialog"
    )
      SuccessDialog(
        :editing="editing"
        @close="closeSuccessDialog"
      )
    NioDialog(
      v-model="errorDialog"
      class="full-dialog"
    )
      NioApiError(
        :error="error"
        @close="errorDialog = false"
      )
</template>

<script>

import SuccessDialog from './SuccessDialog.vue'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { baseUrl, setHeaders, getHeaders } from '@/utils/serviceLayer'
import { getProfileSettings } from '@/components/shared/profilesModule'
import axios from 'axios'

  export default {
    components: {
      SuccessDialog
    },
    data: () => ({
      loading: true,
      profileSettings: null,
      existingProfile: null,
      editing: false,
      errorDialog: false,
      error: null,
      model: {
        brandId: null
      },
      rules: {
        required: function(value) { return !!value || 'Required' }
      },
      successDialog: false
    }),
    computed: {
      valid() {
        if (!this.model.brandId) {
          return false
        }
        const brandIdValid = this.model.brandId.length > 0
        if (this.existingProfile) {
          return brandIdValid && this.model.brandId !== this.existingProfile.profile.brand_id
        }
        return brandIdValid
      }
    },
    mounted() {
      NioOpenApiModule.initCallback(this.openApiInit)
    },
    methods: {
      openApiInit(token) {
        setHeaders(token)
        this.getProfileSettings()
      },
      async getProfileSettings() {
        let existingProfile
        try {
          existingProfile = await getProfileSettings(baseUrl, getHeaders())
          if (existingProfile) {
            this.existingProfile = existingProfile
            this.model.brandId = existingProfile.profile.brand_id
          }
        } catch (error) {}
        this.loading = false
      },
      async saveSettings() {
        this.loading = true
        const payload = {
          name: 'Narrative The Trade Desk Connector',
          description: null,
          credentials:
            {
              secret_key: this.model.secretKey
          },
          profile:{
            brand_id: this.model.brandId,
            company_name: this.nioUser.companyName
          }
        }
        try {
          if (this.existingProfile) {
            await axios.put(`${baseUrl}/profiles/${this.existingProfile.api_profile.id}`, payload, getHeaders())
          } else {
            const createResp = await axios.post(`${baseUrl}/profiles`, payload, getHeaders())
            await axios.post(`${baseUrl}/profiles/${createResp.data.api_profile.id}/enable`, {}, getHeaders())
          }
          this.successDialog = true
        } catch(e) {
          parent.postMessage({
            name: 'scrollTo',
            payload: {
              x: 0,
              y: 0
            }
          },"*")
          this.error = e
          this.errorDialog = true
        }
        this.loading = false
      },
      closeSuccessDialog() {
        this.existingProfile = null
        this.editing = false
        this.successDialog = false
        this.loading = true
        this.getProfileSettings()
      }
    }
  };
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-group"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.profile-settings-page
  padding: 2rem
  h1
    margin-bottom: 1.5rem
  .sub-container
    +nio-filter-group
    & .filter + .filter
      border-top: 0.0625rem solid $c-primary-lighter
      border-top-left-radius: 0rem
      border-top-right-radius: 0rem
    .sub-container
      border: 0.0625rem solid $c-primary-lighter
      border-radius: 0.75rem
      overflow: hidden
    & .sub-container + .sub-container
      margin-top: 1.5rem
    .filter
      +nio-filter-header
      background-color: $c-white
      .title-description
        .description
          max-width: 22.5rem
      .filter-value
        width: 32.375rem
        .nio-text-field, .nio-tags-field
          width: 100%
          flex: unset
  .actions
    display: flex
    justify-content: flex-end
    margin-top: 1.5rem

</style>